import React from "react"

export const Map = ({}: React.SVGProps<SVGSVGElement>) => (

    <svg width="248" height="340" viewBox="0 0 248 396" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
          <g opacity= ".7" >
              <path fill="#626262" d="m198.378 195.024-1.274.095-.162 1.242-4.962-.124-.014 1.284-.936.036.114 8.226-1.295-.236.007.734-5.967.099.167 7.082-5.7.077-.163-4.478-.71-2.776.019-5.356-1.24-1.734 1.75-5.663-4.848-.179-.114-.96-1.427.205-.073.953-1.647.088.018-.477.163-27.366 1.726.027.133-1.543 22.265-.405 3.818.228z"/>
              <path fill="#626262" d="m134.951 170.788.848-5.499 1.378-.922 21.578.083 11.576.04.063 1.337-.163 27.551-21.22-.147.087-1.44-1.492-.013.217 1.302-2.872.016-2.51-1.93-.263-1.117-1.336-.192-1.764-2.016.578-2.868-.348-.787-2.015-1.249-.638-2.241-1.57-2.308.18-3.33-.71-2.392zM70.368 228.782l-4.054-.158.299-14.278.219-14.007 3.151-.081.184-13.701 3.228.105.19-3.61 7.276.236.145 3.36 20.165-.06-.051 10.39-1.185 1.369-.748 4.546.482 2.772-2.626 1.404-.063 2.427-2.49 4.826-21.084.39.136 13.992z"/>
              <path fill="#626262" d="m178.615 213.25 1.107.952-1.195 2.286-2.803.077-1.319.789-1.926-.727-.509.819-.67-.826-.852 2.117-1.093.653-.961.11-.661.923-.992-.072-1.586-.93-3.076-.51.06-.653-1.342.462-1.618-1.111-.688.668-.688-.278.3-1.228-4.902-4.21-.451 1.332-1.799.851-.64-.677-.565.345-.932 2.482 1.202 2.588-.377.242-.6-.784-1.641-.455-1.49.461-1.865-.352-.151 1.197-.782.265-.52-23.411-.18-3.583 2.787-.349 2.848-.017-.216-1.285 1.48.013-.086 1.422 21.04.145-.019.474 1.631-.086.072-.947 1.414-.204.112.954 4.801.177-1.732 5.63 1.227 1.724-.019 5.325.706 2.76zM114.76 225.862l.422-1.845-2.122-3.191.97-1.686-2.205-1.555-.048-.95-1.175-1.562.007-1.495-1.689-3.037.322-3.603-.707-6.553 13.646.09-.003-3.655 20.202.107.521 23.442-1.354.831-1.902-.461-.663 2.238-1.495.682-.837 1.94-2.555.394-.13-1.68-2.175-1.158-1.067 1.133-1.818-.784-.329-2.524-2.054-.315-.727 2.09-2.306 1.936-2.05-.46-.202-3.175-1.726 1.044-2.083-.607-.653 2.168 1.138 1.452-.303 1.558-1.395 1.125-1.179.003z"/>
              <path fill="#626262" d="m70.732 231.586.098-2.867 3.164-.079-.135-14.035 21.02-.39 2.483-4.841.063-2.434 2.62-1.407-.482-2.782.745-4.558 1.182-1.373-.042 3.064 6.98.075.706 6.627-.321 3.643 1.687 3.07-.006 1.512 1.173 1.58.047.96 2.204 1.572-.968 1.705 2.118 3.226-.422 1.866-1.584.104-.263 1.501 1.29 1.215-2.552 3.203 2.287 2.307 1.464.225.12.923-6.614.037-.267 13.846-17.535.353-.012-1.028-.864-.645.143-1.355-2.689-1.057-1.31-1.979-.993.05.223-1.648-1.9-.413-.118-1.214-1.35-.326-.127-.884-1.198-.58.06-1.028-1.935-.522-1.147.379-.54-2.145-.97-.979-1.936-.557-1.058-2.008zM174.868 231.592l.077-1.02-1.322-1.4-.548-2.177-2.465-1.625-1.688-5.546 1.103-.655.86-2.128.676.83.515-.822 1.943.73 1.333-.793 2.83-.078 1.206-2.297-1.116-.957 5.699-.077-.167-7.074 5.966-.099-.006-.733 1.294.234v.916l1.526.06 12.504 6.514 3.615-.068.476 7.01-.175 14.285-28.652.06-1.676-1.706.5-2.392-2.093-1.577-.177 2.697z"/>
              <path fill="#626262" d="m170.714 249.137-.2 6.41-12.775.28-7.708-.043.046-6.82-6.864-.08-.412-15.62-2.033-1.24-.75.343-1.07-1.32-1.259-.003-.482-2.259.723-1.779-1.41-1.075.052-.513.842-1.96 1.504-.688.667-2.261 1.913.466 1.362-.84.788-.268.152-1.21 1.88.356 1.5-.467 1.652.46.604.794.38-.245-1.21-2.618.938-2.51.57-.35.644.685 1.812-.859.454-1.348 4.938 4.258-.303 1.241.695.282.692-.676 1.63 1.125 1.352-.469-.061.662 3.098.514 1.599.942.997.072.666-.932.97-.113 1.684 5.583 2.459 1.635.547 2.19 1.317 1.41-.076 1.027-3.848-.068.022 1.398-.86.224z"/>
              <path fill="#626262" d="m140.094 255.98-28.068.338.163-7.15-3.654-.136.268-13.843 6.668-.036-.12-.923-1.476-.225-2.307-2.307 2.573-3.202-1.3-1.214.264-1.503 1.598-.102.279 1.958 1.187-.003 1.404-1.138.304-1.574-1.144-1.468.657-2.191 2.097.613 1.739-1.055.202 3.208 2.065.466 2.32-1.956.734-2.114 2.068.32.33 2.55 1.832.793 1.074-1.146 2.19 1.172.132 1.697 2.572-.398-.053.513 1.411 1.076-.724 1.78.485 2.26 1.26.003 1.068 1.321.753-.344 2.035 1.243.412 15.63-.183 6.673zM210.629 241.203l.001.808-1.07-.047.055 9.827-1.886.065-.228-.704-.928.107.007.697-2.724-.225-.07 1.682-4.754-.233-5.614 10.023-8.873-.277.282-7.198-6.163.02.088-1.351-1.155-.666-.094-4.327-.995-.036-.085-.7-3.521-.644.04 1.87-1.22.029-.116-.848-1.04.11-.172-16.253.86-.225-.021-1.396 3.85.068 2.033.114.177-2.71 2.09 1.583-.502 2.404 1.674 1.716 28.59-.061.241 6.432 1.266-.204zM198.728 194.854l-.35-30.993 24.536-.478 3.584 20.116 1.25 5.205 2.324 2.455-.353 1.678.57.963-10.022.018-.065.66-8.641.14-.624-1.021-1.226.058-.082 1.027-3.505-.332z"/>
              <path fill="#626262" d="m232.626 205.437.908 1.747 5.102 5.003-4.391.174.017 2.782-18.349.603-.06 4.677-6.747-.144-.472-7.003-3.587.068-12.407-6.51-1.513-.06v-.914l-.113-8.21.927-.036.016-1.283 4.92.125.163-1.24 1.264-.096 7.394-.501 3.506.332.082-1.023 1.225-.058.622 1.016 8.64-.139.066-.656 10.022-.018.747.804.418 3.572 1.171 1.11-.905 2.43 1.187 1.404z"/>
              <path fill="#626262" d="m209.18 234.387.174-14.246 6.788.143.06-4.668 18.456-.603-.017-2.775 4.417-.174.399 5.131 1.668 2.032-.38 2.611-.925.777.876 1.247-2.078 1.952-.221 1.398 5.755 2.913 1.808 1.95.04.763-.775.768-1.251.174.524 1.503-.845.845-3.235.457 1.048 1.621-3.24 1.042-1.13 1.005-1.976 3.162.487 2.85-.715 1.694-4.758-.68.006.655-4.103-.004-.312-1.25-2.03-1.692-.597-1.48-1.975-2.163-10.46-.233.023-.544-1.267.201z"/>
              <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m209.18 234.387.174-14.246 6.788.143.06-4.668 18.456-.603-.017-2.775 4.417-.174.399 5.131 1.668 2.032-.38 2.611-.925.777.876 1.247-2.078 1.952-.221 1.398 5.755 2.913 1.808 1.95.04.763-.775.768-1.251.174.524 1.503-.845.845-3.235.457 1.048 1.621-3.24 1.042-1.13 1.005-1.976 3.162.487 2.85-.715 1.694-4.758-.68.006.655-4.103-.004-.312-1.25-2.03-1.692-.597-1.48-1.975-2.163-10.46-.233.023-.544-1.267.201zM166.467 52.174l-3.525 4.07.063 1.424-1.33.03.297 1.244 1.365.01.443 16.019-.066.745-6.115.795-7.603 6.722-9.92-13.204-.65-2.151-1.435-.12-.074-1.236-6.773-.134-.025-7.706 2.313-12.065.673 3.956 2.707 1.995 1.89.246 3.56-.974 2.184-1.765.11-1.008 1.353.456.74-.576 1.267.717 1.851-1.963.263-2.166-.889-.667 1.236-1.684-1.288-.117-.379-1.138 12.467.049-.649 4.327 1.07.9-.626.465.039 1.05.839-.082.496.954 1.151-1.383.827.254.12 4.013zM198.378 195.024l-1.274.095-.162 1.242-4.962-.124-.014 1.284-.936.036.114 8.226-1.295-.236.007.734-5.967.099.167 7.082-5.7.077-.163-4.478-.71-2.776.019-5.356-1.24-1.734 1.75-5.663-4.848-.179-.114-.96-1.427.205-.073.953-1.647.088.018-.477.163-27.366 1.726.027.133-1.543 22.265-.405 3.818.228z"/>
              <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m134.951 170.788.848-5.499 1.378-.922 21.578.083 11.576.04.063 1.337-.163 27.551-21.22-.147.087-1.44-1.492-.013.217 1.302-2.872.016-2.51-1.93-.263-1.117-1.336-.192-1.764-2.016.578-2.868-.348-.787-2.015-1.249-.638-2.241-1.57-2.308.18-3.33-.71-2.392zM70.368 228.782l-4.054-.158.299-14.278.219-14.007 3.151-.081.184-13.701 3.228.105.19-3.61 7.276.236.145 3.36 20.165-.06-.051 10.39-1.185 1.369-.748 4.546.482 2.772-2.626 1.404-.063 2.427-2.49 4.826-21.084.39.136 13.992z"/>
              <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m178.615 213.25 1.107.952-1.195 2.286-2.803.077-1.319.789-1.926-.727-.509.819-.67-.826-.852 2.117-1.093.653-.961.11-.661.923-.992-.072-1.586-.93-3.076-.51.06-.653-1.342.462-1.618-1.111-.688.668-.688-.278.3-1.228-4.902-4.21-.451 1.332-1.799.851-.64-.677-.565.345-.932 2.482 1.202 2.588-.377.242-.6-.784-1.641-.455-1.49.461-1.865-.352-.151 1.197-.782.265-.52-23.411-.18-3.583 2.787-.349 2.848-.017-.216-1.285 1.48.013-.086 1.422 21.04.145-.019.474 1.631-.086.072-.947 1.414-.204.112.954 4.801.177-1.732 5.63 1.227 1.724-.019 5.325.706 2.76zM114.76 225.862l.422-1.845-2.122-3.191.97-1.686-2.205-1.555-.048-.95-1.175-1.562.007-1.495-1.689-3.037.322-3.603-.707-6.553 13.646.09-.003-3.655 20.202.107.521 23.442-1.354.831-1.902-.461-.663 2.238-1.495.682-.837 1.94-2.555.394-.13-1.68-2.175-1.158-1.067 1.133-1.818-.784-.329-2.524-2.054-.315-.727 2.09-2.306 1.936-2.05-.46-.202-3.175-1.726 1.044-2.083-.607-.653 2.168 1.138 1.452-.303 1.558-1.395 1.125-1.179.003z"/>
              <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m70.732 231.586.098-2.867 3.164-.079-.135-14.035 21.02-.39 2.483-4.841.063-2.434 2.62-1.407-.482-2.782.745-4.558 1.182-1.373-.042 3.064 6.98.075.706 6.627-.321 3.643 1.687 3.07-.006 1.512 1.173 1.58.047.96 2.204 1.572-.968 1.705 2.118 3.226-.422 1.866-1.584.104-.263 1.501 1.29 1.215-2.552 3.203 2.287 2.307 1.464.225.12.923-6.614.037-.267 13.846-17.535.353-.012-1.028-.864-.645.143-1.355-2.689-1.057-1.31-1.979-.993.05.223-1.648-1.9-.413-.118-1.214-1.35-.326-.127-.884-1.198-.58.06-1.028-1.935-.522-1.147.379-.54-2.145-.97-.979-1.936-.557-1.058-2.008zM174.868 231.592l.077-1.02-1.322-1.4-.548-2.177-2.465-1.625-1.688-5.546 1.103-.655.86-2.128.676.83.515-.822 1.943.73 1.333-.793 2.83-.078 1.206-2.297-1.116-.957 5.699-.077-.167-7.074 5.966-.099-.006-.733 1.294.234v.916l1.526.06 12.504 6.514 3.615-.068.476 7.01-.175 14.285-28.652.06-1.676-1.706.5-2.392-2.093-1.577-.177 2.697z"/>
              <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m170.714 249.137-.2 6.41-12.775.28-7.708-.043.046-6.82-6.864-.08-.412-15.62-2.033-1.24-.75.343-1.07-1.32-1.259-.003-.482-2.259.723-1.779-1.41-1.075.052-.513.842-1.96 1.504-.688.667-2.261 1.913.466 1.362-.84.788-.268.152-1.21 1.88.356 1.5-.467 1.652.46.604.794.38-.245-1.21-2.618.938-2.51.57-.35.644.685 1.812-.859.454-1.348 4.938 4.258-.303 1.241.695.282.692-.676 1.63 1.125 1.352-.469-.061.662 3.098.514 1.599.942.997.072.666-.932.97-.113 1.684 5.583 2.459 1.635.547 2.19 1.317 1.41-.076 1.027-3.848-.068.022 1.398-.86.224z"/>
              <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m140.094 255.98-28.068.338.163-7.15-3.654-.136.268-13.843 6.668-.036-.12-.923-1.476-.225-2.307-2.307 2.573-3.202-1.3-1.214.264-1.503 1.598-.102.279 1.958 1.187-.003 1.404-1.138.304-1.574-1.144-1.468.657-2.191 2.097.613 1.739-1.055.202 3.208 2.065.466 2.32-1.956.734-2.114 2.068.32.33 2.55 1.832.793 1.074-1.146 2.19 1.172.132 1.697 2.572-.398-.053.513 1.411 1.076-.724 1.78.485 2.26 1.26.003 1.068 1.321.753-.344 2.035 1.243.412 15.63-.183 6.673zM210.629 241.203l.001.808-1.07-.047.055 9.827-1.886.065-.228-.704-.928.107.007.697-2.724-.225-.07 1.682-4.754-.233-5.614 10.023-8.873-.277.282-7.198-6.163.02.088-1.351-1.155-.666-.094-4.327-.995-.036-.085-.7-3.521-.644.04 1.87-1.22.029-.116-.848-1.04.11-.172-16.253.86-.225-.021-1.396 3.85.068 2.033.114.177-2.71 2.09 1.583-.502 2.404 1.674 1.716 28.59-.061.241 6.432 1.266-.204zM198.728 194.854l-.35-30.993 24.536-.478 3.584 20.116 1.25 5.205 2.324 2.455-.353 1.678.57.963-10.022.018-.065.66-8.641.14-.624-1.021-1.226.058-.082 1.027-3.505-.332z"/>
              <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m232.626 205.437.908 1.747 5.102 5.003-4.391.174.017 2.782-18.349.603-.06 4.677-6.747-.144-.472-7.003-3.587.068-12.407-6.51-1.513-.06v-.914l-.113-8.21.927-.036.016-1.283 4.92.125.163-1.24 1.264-.096 7.394-.501 3.506.332.082-1.023 1.225-.058.622 1.016 8.64-.139.066-.656 10.022-.018.747.804.418 3.572 1.171 1.11-.905 2.43 1.187 1.404z"/>
          </g>
        <g opacity=".7">
            <path fill="#E7CEB5" d="m20.656 8.204.065.745 3.468 6.084 2.33 2.49 1.255.353 4.254-1.017 4.628 1.896 1.417 2.07 1.273 3.59 4.232 4.423 3.399 1.767 2.971.014 4.209-2.786 10.23-4.153 2.671-.095 6.044 2.691 2.7-1.354 2.668.17 3.932 4.588 1.252.251 1.087-.793-.753-1.988 1.547-.894 1.886-2.225.494-15.37-1.034.051-26.228-.262L34.885 8z"/>
            <path fill="#E7CEB5" d="m87.911 24.43-1.885 2.235-1.547.9.753 1.997.74-.731 2.78.14 4.193 3.128 2.921 3.432 2.725 2.121 2.609.7 6.298 4.866 6.508 2.598 2.965.046.401-35.98-3.477-.097-25.49-.802z"/>
            <path fill="#E7CEB5" d="m116.881 45.747.402-36.272 32.059.15.653-.017-.714 1.79 1.339 1.253 3.291 1.242.282.727-1.524.817-1.763-1.078-2.045.385.187 2.384-1.536 1.233-.35 1.308-.082 1.318 1.017 2.711-.363 6.244-.956 1.136-.017 8.432 1.984 2.322.38 1.141 1.294.118-1.24 1.689.892.668-.264 2.172-1.86 1.969-1.27-.72-.744.578-1.358-.457-.11 1.01-2.193 1.77-3.575.976-1.896-.247-2.718-2-.675-3.966-1.162-1.877-2.67-1.209-1.285.826-1.404 2.252-1.29-.188-.662-2.125-1.347-.83-1.608.179-2.86 2.55z"/>
            <path fill="#E7CEB5" d="m149.577 9.475 29.689.256 17.83.184 1.773 10.616-1.654 1.068-1.359 2.245-1.009 3.991-1.739 2.229-1.978.6-5.976 9.665-10.382 7.502-1.609 2.157-3.152 1.575-3.831.389-2.034.303-.12-4.024-.832-.254-1.157 1.386-.5-.955-.842.083-.04-1.053.628-.467-1.074-.902.652-4.336-12.536-.05-1.987-2.32.017-8.428.957-1.136.364-6.242-1.02-2.708.083-1.318.351-1.308 1.538-1.233-.187-2.382 2.048-.385 1.768 1.077 1.525-.815-.283-.727-3.297-1.243-1.339-1.251z"/>
            <path fill="#E7CEB5" d="m203.779 48.788-1.341.843-.091 1.269-1.102-.944-.675.683-.388 1.969-1.076 1.191-.248 2.273-2.494 4.236-1.029.969-1.757.266-.37 1.63-7.415 6.956-2.192-.195-.331 1.667-2.008-.039-.085 3.17-17.513.142-.443-16.001-1.367-.01-.297-1.245 1.33-.029-.06-1.422 3.53-4.066 3.817-.387 3.14-1.569 1.604-2.15 10.343-7.478 5.954-9.633 1.971-.597 1.732-2.221 1.005-3.98 1.355-2.236 1.647-1.065 3.297 19.805.604 3.117zM65.823 66.13l.251-20.946 1.677-1.277-.12-1.577 1.746-2.662.263-1.533 2.496-.06-.623-3.013 1.76-1.5-.387-1.585.96-3.359-.532-2.038 2.723-1.37 2.687.173 3.968 4.633 1.261.253 1.096-.8.747-.734 2.803.14 4.226 3.142 2.946 3.45-.824 30.456-.027 1.083z"/>
            <path fill="#E7CEB5" d="m133.082 46.72-2.31 12.078.026 7.717-36.01-.473.815-30.506 2.717 2.135 2.602.705 6.282 4.897 6.488 2.617 2.957.045 2.227.364 2.843-2.546 1.599-.178 1.34.827.658 2.12 1.283.189 1.395-2.247 1.278-.825 2.656 1.207z"/>
            <path fill="#E7CEB5" d="m166.467 52.174-3.525 4.07.063 1.424-1.33.03.297 1.244 1.365.01.443 16.019-.066.745-6.115.795-7.603 6.722-9.92-13.204-.65-2.151-1.435-.12-.074-1.236-6.773-.134-.025-7.706 2.313-12.065.673 3.956 2.707 1.995 1.89.246 3.56-.974 2.184-1.765.11-1.008 1.353.456.74-.576 1.267.717 1.851-1.963.263-2.166-.889-.667 1.236-1.684-1.288-.117-.379-1.138 12.467.049-.649 4.327 1.07.9-.626.465.039 1.05.839-.082.496.954 1.151-1.383.827.254.12 4.013zM37.41 97.622l-.05-8.338-2.19-.109-.224-2.488-14.781.008.225-2.556 2.164-19.1 36.396.937-.272 24.944-.075 7.556z"/>
            <path fill="#E7CEB5" d="m94.789 92.418.001-25.31.028-1.085 36.18.473 6.793.134.075 1.236 1.438.12.652 2.152-4.341.463-.71.8.116 1.125-2.35 2.356.641 1.29-2.945 3.235-.668 1.995-.736.539-1.117-.669-.647 1.4-.861-.361.077 1.196-2.121 1.243-.964-.7-.391.437 1.257 2.275-1.094-.182-.207 1.897-1.219.264-1.463 3.425-1.532.627-1.202 2.313-2.965.551-.37 1.034-.969-.437-.825 1.095-1.78.099-.673 1.1-1.744.214-.789 1.207.487 1.54-.516.916-2.82.969-1.937-.982-.119-1.231-6.226-.233.434-2.15-1.762-2.017zM94.787 92.084l-3.05-.001-.547-.755-1.198-.289-1.896.512-29.146-.517.27-24.96 6.771-.051 28.798.873zM73.15 26.332l.528 2.033-.954 3.347.385 1.58-1.75 1.496.62 3.004-2.482.06-.26 1.527-1.736 2.654.12 1.572-1.668 1.274-41.37-1.048 3.137-25.956 4.264-1.024 4.638 1.908 1.419 2.083 1.277 3.617 4.242 4.45 3.406 1.78 2.98.016 4.216-2.806 10.251-4.182 2.68-.096zM65.575 65.971l-6.754.052-36.201-.943 1.108-11.654 1.008-9.53 41.087 1.053z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m65.575 65.971-6.754.052-36.201-.943 1.108-11.654 1.008-9.53 41.087 1.053zM84.741 29.137l-1.085.793-1.253-.251-3.933-4.588-2.667-.169-2.7 1.354-6.045-2.692-2.672.096-10.23 4.154-4.207 2.785-2.972-.014-3.398-1.769-4.233-4.42-1.273-3.592-1.417-2.07-4.627-1.894-4.255 1.017-1.255-.355-2.33-2.49-3.468-6.081-.065-.747L34.886 8l25.767.45 26.23.262 1.032-.05-.494 15.368-1.885 2.225-1.548.894z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m85.232 29.562-.753-1.998 1.548-.9 1.884-2.235.495-15.446 25.489.8 3.477.098-.4 35.981-2.966-.044-6.506-2.6-6.3-4.866-2.608-.7-2.725-2.121-2.922-3.432-4.192-3.126-2.779-.141z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m116.881 45.747.402-36.272 32.059.15.653-.017-.714 1.79 1.339 1.253 3.291 1.242.282.727-1.524.817-1.763-1.078-2.045.385.187 2.384-1.536 1.233-.35 1.308-.082 1.318 1.017 2.711-.363 6.244-.956 1.136-.017 8.432 1.984 2.322.38 1.141 1.294.118-1.24 1.689.892.668-.264 2.172-1.86 1.969-1.27-.72-.744.578-1.358-.457-.11 1.01-2.193 1.77-3.575.976-1.896-.247-2.718-2-.675-3.966-1.162-1.877-2.67-1.209-1.285.826-1.404 2.252-1.29-.188-.662-2.125-1.347-.83-1.608.179-2.86 2.55z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m149.577 9.475 29.689.256 17.83.184 1.773 10.616-1.654 1.068-1.359 2.245-1.009 3.991-1.739 2.229-1.978.6-5.976 9.665-10.382 7.502-1.609 2.157-3.152 1.575-3.831.389-2.034.303-.12-4.024-.832-.254-1.157 1.386-.5-.955-.842.083-.04-1.053.628-.467-1.074-.902.652-4.336-12.536-.05-1.987-2.32.017-8.428.957-1.136.364-6.242-1.02-2.708.083-1.318.351-1.308 1.538-1.233-.187-2.382 2.048-.385 1.768 1.077 1.525-.815-.283-.727-3.297-1.243-1.339-1.251zM73.15 26.332l.528 2.033-.954 3.347.385 1.58-1.75 1.496.62 3.004-2.482.06-.26 1.527-1.736 2.654.12 1.572-1.668 1.274-41.37-1.048 3.137-25.956 4.264-1.024 4.638 1.908 1.419 2.083 1.277 3.617 4.242 4.45 3.406 1.78 2.98.016 4.216-2.806 10.251-4.182 2.68-.096z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m203.779 48.788-1.341.843-.091 1.269-1.102-.944-.675.683-.388 1.969-1.076 1.191-.248 2.273-2.494 4.236-1.029.969-1.757.266-.37 1.63-7.415 6.956-2.192-.195-.331 1.667-2.008-.039-.085 3.17-17.513.142-.443-16.001-1.367-.01-.297-1.245 1.33-.029-.06-1.422 3.53-4.066 3.817-.387 3.14-1.569 1.604-2.15 10.343-7.478 5.954-9.633 1.971-.597 1.732-2.221 1.005-3.98 1.355-2.236 1.647-1.065 3.297 19.805.604 3.117zM65.823 66.13l.251-20.946 1.677-1.277-.12-1.577 1.746-2.662.263-1.533 2.496-.06-.623-3.013 1.76-1.5-.387-1.585.96-3.359-.532-2.038 2.723-1.37 2.687.173 3.968 4.633 1.261.253 1.096-.8.747-.734 2.803.14 4.226 3.142 2.946 3.45-.824 30.456-.027 1.083z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m133.082 46.72-2.31 12.078.026 7.717-36.01-.473.815-30.506 2.717 2.135 2.602.705 6.282 4.897 6.488 2.617 2.957.045 2.227.364 2.843-2.546 1.599-.178 1.34.827.658 2.12 1.283.189 1.395-2.247 1.278-.825 2.656 1.207zM37.41 97.622l-.05-8.338-2.19-.109-.224-2.488-14.781.008.225-2.556 2.164-19.1 36.396.937-.272 24.944-.075 7.556z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m94.789 92.418.001-25.31.028-1.085 36.18.473 6.793.134.075 1.236 1.438.12.652 2.152-4.341.463-.71.8.116 1.125-2.35 2.356.641 1.29-2.945 3.235-.668 1.995-.736.539-1.117-.669-.647 1.4-.861-.361.077 1.196-2.121 1.243-.964-.7-.391.437 1.257 2.275-1.094-.182-.207 1.897-1.219.264-1.463 3.425-1.532.627-1.202 2.313-2.965.551-.37 1.034-.969-.437-.825 1.095-1.78.099-.673 1.1-1.744.214-.789 1.207.487 1.54-.516.916-2.82.969-1.937-.982-.119-1.231-6.226-.233.434-2.15-1.762-2.017zM94.787 92.084l-3.05-.001-.547-.755-1.198-.289-1.896.512-29.146-.517.27-24.96 6.771-.051 28.798.873z"/>
        </g>
        <g opacity= ".7" >
            <path fill="#34657F" d="m149.985 83.271 7.587-6.715 6.104-.795.064-.743 17.446-.144.111 6.83 1.33-.285 1.026-1.153.888.445.269 2.732 3.664 8.302.085 2.534-.121 2.117-5.73-.669-1.475-.8-.255.772-2.28-.422.11 1.084-1.164-.044-.154 3.007-2.077.22-.058-1.452-1.61-.17.12.821-1.12-.189-.13 1.408-.94.003-.078 1.077-.682.123.096 1.453-1.694.124.004.894-2.34.25-.787-1.07-1.92.08-1.33-2.687-1.761-.173-.665-1.4-.93-.256-.87-3.08-1.887-1.964-1.358.103-2.232 1.412-.971-.219.12-1.261-.709-.21-3.276 3.39.057 1.675-.957.49-2.149-.963 2.184-3.427.417-6.8z"/>
            <path fill="#34657F" d="m184.645 127.358.026-2.562-13.689-.142-.05-.67-1.62.052.196-1.219-.713-1.03.007-1.225-1.235-1.235-.288-1.385-6.704.023.272-3.376 1.696-.014.957-1.455 1.68-.61 2.53-2.141-1.548-3.034.933-1.792-.12-1.866 2.35-.251-.004-.895 1.703-.123-.098-1.458.684-.124.079-1.078.944-.003.13-1.411 1.125.19-.12-.824 1.617.171.058 1.456 2.086-.22.155-3.015 1.169.044-.11-1.085 2.29.422.256-.774 1.48.803 5.757.67.122-2.121 7.46.631.12.714 7.06.213-.032.782-.476 4.082-4.88.21-.129 1.262-2.224-.089.106 2.016 3.202.13-.063 3.312-1.134.066-.01 1.153-1.098.105-.155.999-1.058.032-.14 10.746-1.021.108-.089 1.26-2.445-.06.032 1.079-2.147.027.031 1.116-1.465-.057-.106 3.6z"/>
            <path fill="#34657F" d="m137.2 164.285.493-1.386 1.236-.108-.014-.701-1.478-1.668-.918-2.87 1.315-1.323 1.115.183-.12-2.034.524-1.202 1.686-.056.437-.798-.685-1.942 2.963-.127 2.27-1.883-.844-1.616.359-1.76 1.266-.884-.152-1.077-1.982-2.392 2.674-1.43 3.889-7.342.907-.156.445 1.236 1.17-.145 1.031-1.63 2.59-2.095.102-.738-1.423-1.196.037-1.073 1.49-.69-.42-1.94 1.615-1.02.53-1.13-.57-1.984-2.011-1.627 1.842-1.01.926 1.477 1.05.073 6.699-.023.288 1.383 1.232 1.23-.007 1.222.714 1.026-.195 1.216 1.618-.053.05.67 13.678.14-.026 2.555.036.965-4.328 4.056-3.535.163-.092 3.363-1.011-.037-.024 4.547-1.197-.08-.048 1.255-2.584-.058.107 7.135-9.737.02-.02 7.825-3.553-.013.103 7.639z"/>
            <path fill="#34657F" d="m170.333 165.816-.063-1.34-11.555-.041-.103-7.703 3.558.013.019-7.892 9.749-.02-.107-7.197 2.588.06.047-1.267 1.198.082.025-4.587 1.012.037.092-3.391 3.54-.165-.515 1.659-1.538-.095-.149.965 6.956-.145.124-1.227 1.166.008.074-.967 8.49.15-.558 31.126-22.198.406-.133 1.557zM210.218 84.813l1.416 10.456-6.15.362-.322 1.37-2.022-.271.032-.785-7.036-.213-.12-.716-7.438-.633-.086-2.548-3.668-8.343-.27-2.744-.888-.447-1.027 1.16-1.332.287-.112-6.865.086-3.184 2.002.038.33-1.675 2.187.198 7.395-6.991.37-1.638 1.753-.268 1.025-.972 2.488-4.259.247-2.284 1.074-1.197.387-1.977.673-.688 1.099.949.09-1.276 1.338-.846 3.506 19.246zM195.01 132.736l19.831-.178.518-.645 2.687 1.382.76 5.268 4.096 24.097.024.738-24.666.477-3.81-.228z"/>
            <path fill="#34657F" d="m215.952 119.898 2.064 13.363-2.68-1.38-.517.645-19.786.176-8.476-.149-.076.96-1.162-.008-.124 1.215-6.946.144.15-.957 1.535.095.513-1.645 4.327-4.052-.037-.964 3.337.13.107-3.587 1.463.056-.03-1.112 2.143-.027-.03-1.075 2.44.06.089-1.255 1.02-.108.14-10.708 1.057-.03.154-.997 1.096-.104.012-1.15 1.133-.065.063-3.3-3.2-.13-.106-2.008 2.221.088.129-1.257 4.874-.21.475-4.066 2.027.27.322-1.362 6.162-.36.797 4.445z"/>
            <path stroke= "#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m215.952 119.898 2.064 13.363-2.68-1.38-.517.645-19.786.176-8.476-.149-.076.96-1.162-.008-.124 1.215-6.946.144.15-.957 1.535.095.513-1.645 4.327-4.052-.037-.964 3.337.13.107-3.587 1.463.056-.03-1.112 2.143-.027-.03-1.075 2.44.06.089-1.255 1.02-.108.14-10.708 1.057-.03.154-.997 1.096-.104.012-1.15 1.133-.065.063-3.3-3.2-.13-.106-2.008 2.221.088.129-1.257 4.874-.21.475-4.066 2.027.27.322-1.362 6.162-.36.797 4.445zM149.985 83.271l7.587-6.715 6.104-.795.064-.743 17.446-.144.111 6.83 1.33-.285 1.026-1.153.888.445.269 2.732 3.664 8.302.085 2.534-.121 2.117-5.73-.669-1.475-.8-.255.772-2.28-.422.11 1.084-1.164-.044-.154 3.007-2.077.22-.058-1.452-1.61-.17.12.821-1.12-.189-.13 1.408-.94.003-.078 1.077-.682.123.096 1.453-1.694.124.004.894-2.34.25-.787-1.07-1.92.08-1.33-2.687-1.761-.173-.665-1.4-.93-.256-.87-3.08-1.887-1.964-1.358.103-2.232 1.412-.971-.219.12-1.261-.709-.21-3.276 3.39.057 1.675-.957.49-2.149-.963 2.184-3.427.417-6.8z"/>
            <path stroke= "#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m184.645 127.358.026-2.562-13.689-.142-.05-.67-1.62.052.196-1.219-.713-1.03.007-1.225-1.235-1.235-.288-1.385-6.704.023.272-3.376 1.696-.014.957-1.455 1.68-.61 2.53-2.141-1.548-3.034.933-1.792-.12-1.866 2.35-.251-.004-.895 1.703-.123-.098-1.458.684-.124.079-1.078.944-.003.13-1.411 1.125.19-.12-.824 1.617.171.058 1.456 2.086-.22.155-3.015 1.169.044-.11-1.085 2.29.422.256-.774 1.48.803 5.757.67.122-2.121 7.46.631.12.714 7.06.213-.032.782-.476 4.082-4.88.21-.129 1.262-2.224-.089.106 2.016 3.202.13-.063 3.312-1.134.066-.01 1.153-1.098.105-.155.999-1.058.032-.14 10.746-1.021.108-.089 1.26-2.445-.06.032 1.079-2.147.027.031 1.116-1.465-.057-.106 3.6zM170.333 165.816l-.063-1.34-11.555-.041-.103-7.703 3.558.013.019-7.892 9.749-.02-.107-7.197 2.588.06.047-1.267 1.198.082.025-4.587 1.012.037.092-3.391 3.54-.165-.515 1.659-1.538-.095-.149.965 6.956-.145.124-1.227 1.166.008.074-.967 8.49.15-.558 31.126-22.198.406-.133 1.557z"/>
            <path stroke= "#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m210.218 84.813 1.416 10.456-6.15.362-.322 1.37-2.022-.271.032-.785-7.036-.213-.12-.716-7.438-.633-.086-2.548-3.668-8.343-.27-2.744-.888-.447-1.027 1.16-1.332.287-.112-6.865.086-3.184 2.002.038.33-1.675 2.187.198 7.395-6.991.37-1.638 1.753-.268 1.025-.972 2.488-4.259.247-2.284 1.074-1.197.387-1.977.673-.688 1.099.949.09-1.276 1.338-.846 3.506 19.246zM195.01 132.736l19.831-.178.518-.645 2.687 1.382.76 5.268 4.096 24.097.024.738-24.666.477-3.81-.228zM137.2 164.285l.493-1.386 1.236-.108-.014-.701-1.478-1.668-.918-2.87 1.315-1.323 1.115.183-.12-2.034.524-1.202 1.686-.056.437-.798-.685-1.942 2.963-.127 2.27-1.883-.844-1.616.359-1.76 1.266-.884-.152-1.077-1.982-2.392 2.674-1.43 3.889-7.342.907-.156.445 1.236 1.17-.145 1.031-1.63 2.59-2.095.102-.738-1.423-1.196.037-1.073 1.49-.69-.42-1.94 1.615-1.02.53-1.13-.57-1.984-2.011-1.627 1.842-1.01.926 1.477 1.05.073 6.699-.023.288 1.383 1.232 1.23-.007 1.222.714 1.026-.195 1.216 1.618-.053.05.67 13.678.14-.026 2.555.036.965-4.328 4.056-3.535.163-.092 3.363-1.011-.037-.024 4.547-1.197-.08-.048 1.255-2.584-.058.107 7.135-9.737.02-.02 7.825-3.553-.013.103 7.639z"/>
        </g>
        <g opacity=".7" >
            <path fill="#B6ADA5" d="m45.25 130.274-7.38-.197-.522-32.092 21.259.856.044 3.453 7.601.187.036 14.385 7.39.377-.033 6.59-14.651-.892-.206 2.771-2.036-.038-.357 4.764z"/>
            <path fill="#B6ADA5" d="m45.203 160.394.197-30.007 11.1.165.355-4.76 2.027.04.205-2.77 14.588.89h3.342l.231 1.41 3.38.002.016 5.69 3.415-.069.867 1.424-1.68-.335-.303 1.692-2.759-.691-1.018 1.934.747.054.128.962 1.272.103.017.886 1.982.12.124 1.147 1.967.746.486 1.599 1.045.171-.054 2.212 1.195.14.059.664 1.229.215.088.996 1.12.102-.002 1.585 1.082.1-.03 1.252 2.465.135-.023.884 2.877.145.09.85 1.195.067-.105 2.613-2.403 1.855-1.046-.025-.205-.78-2.8-.104.112 1.475-2.1 1.828-.425 5.106-6.042-.18-2.399 3.624-.08 6.653-7.12.025-19.892-.399-.127-1.141-7.863-.031zM45.007 160.363l-6.115 2.332-2.471 2.947-4.718 1.549-.364 1.874-2.329-1.104-.801 1.352-3.87-.167-.464 1.136-1.277.867-.153 2.944-11.608.108 2.355-24.428 2.057-20.318 22.616.624 7.338.196z"/>
            <path fill="#B6ADA5" d="m73.678 172.516 7.166-.026.082-6.646 2.415-3.62 6.083.177.428-5.1 2.114-1.825-.113-1.475 2.819.106.205.777 1.054.026 2.418-1.853 2.857.146-.128 6.214 2.514.138.095.701.936.135-.082 4.372 1.348.182.033 1.472.788.093.046 1.03 1.2.068.057.663 1.01.064.1.48 1.203-.004.173 17.802-8.993.313-20.328.06-.146-3.339-7.336-.235zM53.927 171.901l.113 1.88-1.479 1.225-.933.02.354 1-.641.32.7.491.052 1.215-1.026 1.012-.855-.162.082 1.583-1.25 1.046-1.036-.46-.865.426-.056 1.065-1.14 1.121-.18 1.836.761 3.248-1.563 2.03-1.54.746-.237.805 1.405.464 1.396-.968.592.194.86 1.889-.864.426-.333 1.977 1.643-.338 1.515 4.653 1.975-.258.658.599-.877 1.158-.918-.64-2.614.688.788 1.665 2.672.158 1.003 2.036-1.52 1.733-.683-1.186-.817.058-.691 3.252-1.293 1.664-.485-1.915-1.783.006-.8 1.195-.913-.895-.041-1.87-1.188-2.258 1.429-.93.866 1.447 1.236-.566-1.159-1.745-2.27.145-3.808-1.127-1.247 1.265-3.55 1.301-3.087.136-1.328-2.992 1.43-1.262.065-1.725 1.178-1.67-.887-.344-2.84.802-1.44-.719-.175-.737 2.365-1.324-.358-.96-1.444-.498.011-3.27.267-.4 1.705.466.773-1.753-2.902-.3-.862-2.144-1.792.27-2.079-.544 1.7-1.594-1.84-1.718-.7-3.21-.967-.27.656-1.61 1.267.164-.27-2.065 1.528.206-.601-2.402.154-2.943 1.282-.867.465-1.135 3.883.165.806-1.35 2.338 1.104.365-1.875 4.734-1.547 2.48-2.947 6.138-2.33.528 10.293 7.882.03z"/>
            <path fill="#B6ADA5" d="m73.678 183.065-.19 3.597-3.213-.107-.182 13.65-3.137.08-.218 13.957-13.729.28-.16-3.19-5.743.043 1.281-1.662.684-3.247.81-.06.68 1.185 1.506-1.732-.994-2.033-2.651-.158-.78-1.664 2.592-.686.91.64.87-1.157-.653-.6-1.959.258-1.502-4.648-1.63.338.33-1.975.857-.426-.853-1.887-.586-.194-1.383.968-1.396-.465.237-.803 1.526-.745 1.55-2.03-.755-3.245.18-1.834 1.129-1.119.056-1.065.859-.425 1.027.459 1.239-1.043-.08-1.583.847.162 1.017-1.01-.051-1.215-.694-.49.635-.32-.352-1 .927-.02 1.466-1.223-.112-1.878 19.77.4zM38.577 228.634l-33.631.64 2.184-21.93 3.13-27.934.586-5.1 11.665-.109.602 2.416-1.53-.207.271 2.077-1.27-.165-.656 1.62.967.272.701 3.227 1.843 1.728-1.702 1.604 2.081.547 1.796-.27.863 2.155 2.906.302-.774 1.762-1.707-.468-.267.402-.012 3.29 1.446.501.359.966-2.368 1.332.175.741 1.442.722 2.844-.807.888.346-1.18 1.68-.064 1.734-1.433 1.27 1.33 3.01 3.09-.137 3.556-1.309 1.249-1.273 3.814 1.135 2.272-.147 1.16 1.755-1.237.57-.868-1.454-1.43.935 1.188 2.27.043 1.881.913.9.647 1.229-3.262-.462-2.821 2.055-1.078 2.195-2.838.985-.42.767-2.398 1.327-.365 4.34 3.56 2.448 1.114-.093 1.651 1.094z"/>
            <path fill="#B6ADA5" d="m56.638 253.634-27.349.226-1.298-1.658-.462 1.486-.436-.26-.933-1.543-.104-2.879 2.008-1.646 1.577.168 1.343-1.327-.306-1.572.659-2.177 2.13-2.192.076-7.149-.756-1.07.092-1.166 1.075-.457.312-1.018 1.78-.812 2.138.034-.208 1.457.693.377.36-1.637-.506-.63-.968-1.621-1.642-1.086-1.108.092-3.538-2.43.363-4.306 2.382-1.317.419-.761 2.821-.98 1.071-2.176 2.805-2.04 3.243.459-.643-1.22.797-1.193 1.774-.005.482 1.911 5.766-.042.161 3.185 13.782-.278-.299 14.21 4.05.157-.097 2.847-3.358.257-.367 10.427-6.617-.025-.32 10.525-2.789-.09zM37.32 97.692l.519 32.254-22.583-.625 1.792-16.68 3.093-25.958 14.724-.008.223 2.507 2.182.11z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m45.203 160.394.197-30.007 11.1.165.355-4.76 2.027.04.205-2.77 14.588.89h3.342l.231 1.41 3.38.002.016 5.69 3.415-.069.867 1.424-1.68-.335-.303 1.692-2.759-.691-1.018 1.934.747.054.128.962 1.272.103.017.886 1.982.12.124 1.147 1.967.746.486 1.599 1.045.171-.054 2.212 1.195.14.059.664 1.229.215.088.996 1.12.102-.002 1.585 1.082.1-.03 1.252 2.465.135-.023.884 2.877.145.09.85 1.195.067-.105 2.613-2.403 1.855-1.046-.025-.205-.78-2.8-.104.112 1.475-2.1 1.828-.425 5.106-6.042-.18-2.399 3.624-.08 6.653-7.12.025-19.892-.399-.127-1.141-7.863-.031z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m73.678 172.516 7.166-.026.082-6.646 2.415-3.62 6.083.177.428-5.1 2.114-1.825-.113-1.475 2.819.106.205.777 1.054.026 2.418-1.853 2.857.146-.128 6.214 2.514.138.095.701.936.135-.082 4.372 1.348.182.033 1.472.788.093.046 1.03 1.2.068.057.663 1.01.064.1.48 1.203-.004.173 17.802-8.993.313-20.328.06-.146-3.339-7.336-.235zM73.678 183.065l-.19 3.597-3.213-.107-.182 13.65-3.137.08-.218 13.957-13.729.28-.16-3.19-5.743.043 1.281-1.662.684-3.247.81-.06.68 1.185 1.506-1.732-.994-2.033-2.651-.158-.78-1.664 2.592-.686.91.64.87-1.157-.653-.6-1.959.258-1.502-4.648-1.63.338.33-1.975.857-.426-.853-1.887-.586-.194-1.383.968-1.396-.465.237-.803 1.526-.745 1.55-2.03-.755-3.245.18-1.834 1.129-1.119.056-1.065.859-.425 1.027.459 1.239-1.043-.08-1.583.847.162 1.017-1.01-.051-1.215-.694-.49.635-.32-.352-1 .927-.02 1.466-1.223-.112-1.878 19.77.4zM38.577 228.634l-33.631.64 2.184-21.93 3.13-27.934.586-5.1 11.665-.109.602 2.416-1.53-.207.271 2.077-1.27-.165-.656 1.62.967.272.701 3.227 1.843 1.728-1.702 1.604 2.081.547 1.796-.27.863 2.155 2.906.302-.774 1.762-1.707-.468-.267.402-.012 3.29 1.446.501.359.966-2.368 1.332.175.741 1.442.722 2.844-.807.888.346-1.18 1.68-.064 1.734-1.433 1.27 1.33 3.01 3.09-.137 3.556-1.309 1.249-1.273 3.814 1.135 2.272-.147 1.16 1.755-1.237.57-.868-1.454-1.43.935 1.188 2.27.043 1.881.913.9.647 1.229-3.262-.462-2.821 2.055-1.078 2.195-2.838.985-.42.767-2.398 1.327-.365 4.34 3.56 2.448 1.114-.093 1.651 1.094z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m56.638 253.634-27.349.226-1.298-1.658-.462 1.486-.436-.26-.933-1.543-.104-2.879 2.008-1.646 1.577.168 1.343-1.327-.306-1.572.659-2.177 2.13-2.192.076-7.149-.756-1.07.092-1.166 1.075-.457.312-1.018 1.78-.812 2.138.034-.208 1.457.693.377.36-1.637-.506-.63-.968-1.621-1.642-1.086-1.108.092-3.538-2.43.363-4.306 2.382-1.317.419-.761 2.821-.98 1.071-2.176 2.805-2.04 3.243.459-.643-1.22.797-1.193 1.774-.005.482 1.911 5.766-.042.161 3.185 13.782-.278-.299 14.21 4.05.157-.097 2.847-3.358.257-.367 10.427-6.617-.025-.32 10.525-2.789-.09zM37.32 97.692l.519 32.254-22.583-.625 1.792-16.68 3.093-25.958 14.724-.008.223 2.507 2.182.11z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m45.25 130.274-7.38-.197-.522-32.092 21.259.856.044 3.453 7.601.187.036 14.385 7.39.377-.033 6.59-14.651-.892-.206 2.771-2.036-.038-.357 4.764z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m45.007 160.363-6.115 2.332-2.471 2.947-4.718 1.549-.364 1.874-2.329-1.104-.801 1.352-3.87-.167-.464 1.136-1.277.867-.153 2.944-11.608.108 2.355-24.428 2.057-20.318 22.616.624 7.338.196z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m53.927 171.901.113 1.88-1.479 1.225-.933.02.354 1-.641.32.7.491.052 1.215-1.026 1.012-.855-.162.082 1.583-1.25 1.046-1.036-.46-.865.426-.056 1.065-1.14 1.121-.18 1.836.761 3.248-1.563 2.03-1.54.746-.237.805 1.405.464 1.396-.968.592.194.86 1.889-.864.426-.333 1.977 1.643-.338 1.515 4.653 1.975-.258.658.599-.877 1.158-.918-.64-2.614.688.788 1.665 2.672.158 1.003 2.036-1.52 1.733-.683-1.186-.817.058-.691 3.252-1.293 1.664-.485-1.915-1.783.006-.8 1.195-.913-.895-.041-1.87-1.188-2.258 1.429-.93.866 1.447 1.236-.566-1.159-1.745-2.27.145-3.808-1.127-1.247 1.265-3.55 1.301-3.087.136-1.328-2.992 1.43-1.262.065-1.725 1.178-1.67-.887-.344-2.84.802-1.44-.719-.175-.737 2.365-1.324-.358-.96-1.444-.498.011-3.27.267-.4 1.705.466.773-1.753-2.902-.3-.862-2.144-1.792.27-2.079-.544 1.7-1.594-1.84-1.718-.7-3.21-.967-.27.656-1.61 1.267.164-.27-2.065 1.528.206-.601-2.402.154-2.943 1.282-.867.465-1.135 3.883.165.806-1.35 2.338 1.104.365-1.875 4.734-1.547 2.48-2.947 6.138-2.33.528 10.293 7.882.03z"/>
        </g>
        <g opacity=".7">
            <path fill="#E07E3C" d="m108.206 266.424-19.16.712-1.754-.731-19.155.58-8.112.03.106-3.485-.983-.673-2.653-.123.1-8.902.055-.944 2.806.09.323-10.508 6.662.023.369-10.412 3.38-.258 2.56-.09 1.069 1.99 1.952.552.98.969.544 2.126 1.157-.375 1.951.517-.06 1.02 1.208.573.128.877 1.362.322.12 1.204 1.915.41-.223 1.632 1.001-.049 1.32 1.96 2.713 1.048-.144 1.343.872.639.011 1.02 17.688-.35 3.658.134-.164 7.089-3.59.23z"/>
            <path fill="#E07E3C" d="m50.25 308.89-.379.758-2.512-2.047-.205 1.27 1.224.786.488 1.173-1.207.414-1.27-.35-.634.936-1.504.665 1.15.855.152 1.713-1.19-.603-.842.313 1.096 1.837-.332.286-1.406-.753-2.945 2.132-1.778-.246.809-2.75-.513-1.974-3.226-.743 1.107-1 1.02.016.012-.836.926-.932-1.258-1.038.118-.508 2.184.073.325-2.033.93.998 1.322-2.744-1.104-3.696-1.346-.205.866-1.625-1.335-.86 1.258-1.072.634-2.978-1.888-2.839-2.293-.401-4.49-2.478-.316-.807 1.502-.27-4.32-1.707-.924-4.32.749-1.155-.807-2.35-.374-4.351-1.047-.965-2.237.187-.688-.522-1.623-4.37 1.614-2.793 1.224-.934.632-3.173 5.117-1.132-.643-1.143-1.954.592-.503-.775-1.606-.61 1.895-1.508 1.761.026.317-1.496-.54-.741 27.363-.227-.1 8.964 2.637.125.978.678-.106 3.507 8.063-.03-.016.707-1.14.195.21 9.212-3.154.336-.597 2.398.601 1.035-.242 8.278-2.96 1.909-.422 1.013 3.197 2.542.078 3.318-1.33.848-.98 1.546-.84.13-1.79-1.712-.73.023-3.025 1.871-.794 1.515-.96.564.319 2.446-.449.683-1.168.087-1.35-1.845-.353 1.378-1.439.993.193 1.386z"/>
            <path fill="#E07E3C" d="m73.556 312.074-4.059.06-.788.963-3.763 1.736-4.182-.99-3.419-.794-1.204-1.983-2.561-1.826-3.139-.313-1.117-.928-.193-1.384 1.444-.99.355-1.375 1.356 1.842 1.173-.088.451-.682-.32-2.44.963-.564.798-1.51 3.038-1.869.734-.022 1.795 1.707.846-.13.982-1.542 1.337-.846-.079-3.312-3.21-2.537.423-1.01 2.972-1.906.244-8.26-.604-1.033.601-2.392 3.167-.336-.211-9.193 1.145-.196.015-.704 19.117-.582 1.748.735 19.124-.716-.186 6.78-6.808 2.955-2.357.159-.615 1.076.385 2.124-1.985 1.516-3.105.761-.653 4.062-1.659 3.273-.251 2.76-2.093 4.605-1.997.789-2.484 2.778-2.228 1.329-3.529 3.844-1.27 2.696-2.3 1.51z"/>
            <path fill="#E07E3C" d="m122.429 317.525-.84.011-.677.74-1.214-.867-1.75-.05-.368-3.655.668-1.606-45.06-.155 1.84-2.378 2.297-1.502 1.267-2.682 3.522-3.823 2.225-1.322 2.48-2.763 1.993-.783 2.09-4.58.25-2.745 1.656-3.253.652-4.04 3.1-.756 1.981-1.51-.384-2.11.614-1.072 2.353-.157 6.795-2.938.523 11.645 1.294.94 1.373 3.297 1.85 1.897 9.437.268-.126 6.725 1.86.162 1.097 3.612-.443 1.397-2.251 1.959zM47.323 308.725l1.226.785.491 1.171-1.212.415-1.27-.35-.638.933-1.505.664 1.152.855.152 1.71-1.192-.602-.844.312 1.098 1.835-.332.284-1.41-.75-2.95 2.128-1.78-.246.296 1.819-2.45.686 2.063.722.39.659-.676 1.223.24.983-1.74 1.822.88 1.755.976.415-.35 1.443-.81.568-.453 5.5 2.435 2.105-4.137 4.315-.05.94 2.251 2.135.52 1.42-.448.824-1.125.09-3.191 2.302.045 4.123 7.246 9.725.675 15.774 9.94 9.602-1.528 2.11L34.148 394l14.114-.026 13.75-3.5.398-3.217 8.249-3.188 4.167-6.355-.923-3.261-1.808-3.921 1.587-1.982 1.96-5.022-.365-3.786-1.38-1.96-2.81-1.028-4.525-3.376-1.042-2.944-5.04-4.954-.667-2.266 2.44-6.139-.592-3.827-1.14-.327.202-19.26-3.41-.792-1.203-1.984-2.554-1.829-3.132-.311-.378.756-2.518-2.044z"/>
            <path fill="#E07E3C" d="m91.287 333.118-29.723.042-1.141-.327.202-19.188 4.18.989 3.764-1.732.788-.96 4.059-.06 45.136.156-.668 1.61.37 3.664 1.751.05 1.217.868.678-.74.84-.012.033 16.04-5.292-.007zM5.22 228.925l-.817 6.514L2 262.472l.537 14.846 25.64.144-.375-4.32-1.053-.959-2.246.186-.692-.518-1.63-4.34 1.623-2.773 1.228-.927.636-3.15 5.136-1.123-.645-1.137-1.96.588-.507-.77-1.612-.605 1.902-1.497 1.769.025.318-1.485-.541-.736-1.304-1.654-.465 1.483-.437-.26-.937-1.54-.106-2.875 2.018-1.644 1.585.17 1.349-1.327-.308-1.57.66-2.173 2.142-2.188.078-7.137-.761-1.07.091-1.165 1.082-.456.312-1.016 1.79-.811 2.147.034-.21 1.455.697.376.36-1.635-.506-.628zM3.46 298.697l1.102 24.987 7.327-.202-.117-2.32 21.221-.138 1.51-1.388 1.369.565 2.441-.685-.295-1.816.807-2.74-.513-1.966-3.22-.74 1.106-.997 1.017.015.014-.833.923-.928-1.255-1.035.118-.507 2.18.074.324-2.026.928.994 1.32-2.734-1.103-3.684-1.343-.204.865-1.619-1.333-.856 1.255-1.07.634-2.967-1.885-2.828-2.289-.402-4.48-2.468-.317-.805 1.5-.267-4.314-1.702-.92-4.304.746-1.153-.804-2.34-25.488-.146z"/>
            <path fill="#E07E3C" d="m4.82 333.314-.365-9.517 7.35-.203-.117-2.318 21.29-.139 1.514-1.387 1.375.565 2.062.72.39.66-.675 1.218.239.98-1.74 1.819.88 1.75.975.415-.35 1.44-.81.565-.451 5.489 2.434 2.1-4.135 4.305-.051.938 2.25 2.13.521 1.417-.448.823-1.126.09-3.188 2.296.045 4.114-7.89 32.057-12.569-5.279-5.52.845-1.077-27.012z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m108.206 266.424-19.16.712-1.754-.731-19.155.58-8.112.03.106-3.485-.983-.673-2.653-.123.1-8.902.055-.944 2.806.09.323-10.508 6.662.023.369-10.412 3.38-.258 2.56-.09 1.069 1.99 1.952.552.98.969.544 2.126 1.157-.375 1.951.517-.06 1.02 1.208.573.128.877 1.362.322.12 1.204 1.915.41-.223 1.632 1.001-.049 1.32 1.96 2.713 1.048-.144 1.343.872.639.011 1.02 17.688-.35 3.658.134-.164 7.089-3.59.23z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m50.25 308.89-.379.758-2.512-2.047-.205 1.27 1.224.786.488 1.173-1.207.414-1.27-.35-.634.936-1.504.665 1.15.855.152 1.713-1.19-.603-.842.313 1.096 1.837-.332.286-1.406-.753-2.945 2.132-1.778-.246.809-2.75-.513-1.974-3.226-.743 1.107-1 1.02.016.012-.836.926-.932-1.258-1.038.118-.508 2.184.073.325-2.033.93.998 1.322-2.744-1.104-3.696-1.346-.205.866-1.625-1.335-.86 1.258-1.072.634-2.978-1.888-2.839-2.293-.401-4.49-2.478-.316-.807 1.502-.27-4.32-1.707-.924-4.32.749-1.155-.807-2.35-.374-4.351-1.047-.965-2.237.187-.688-.522-1.623-4.37 1.614-2.793 1.224-.934.632-3.173 5.117-1.132-.643-1.143-1.954.592-.503-.775-1.606-.61 1.895-1.508 1.761.026.317-1.496-.54-.741 27.363-.227-.1 8.964 2.637.125.978.678-.106 3.507 8.063-.03-.016.707-1.14.195.21 9.212-3.154.336-.597 2.398.601 1.035-.242 8.278-2.96 1.909-.422 1.013 3.197 2.542.078 3.318-1.33.848-.98 1.546-.84.13-1.79-1.712-.73.023-3.025 1.871-.794 1.515-.96.564.319 2.446-.449.683-1.168.087-1.35-1.845-.353 1.378-1.439.993.193 1.386z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m73.556 312.074-4.059.06-.788.963-3.763 1.736-4.182-.99-3.419-.794-1.204-1.983-2.561-1.826-3.139-.313-1.117-.928-.193-1.384 1.444-.99.355-1.375 1.356 1.842 1.173-.088.451-.682-.32-2.44.963-.564.798-1.51 3.038-1.869.734-.022 1.795 1.707.846-.13.982-1.542 1.337-.846-.079-3.312-3.21-2.537.423-1.01 2.972-1.906.244-8.26-.604-1.033.601-2.392 3.167-.336-.211-9.193 1.145-.196.015-.704 19.117-.582 1.748.735 19.124-.716-.186 6.78-6.808 2.955-2.357.159-.615 1.076.385 2.124-1.985 1.516-3.105.761-.653 4.062-1.659 3.273-.251 2.76-2.093 4.605-1.997.789-2.484 2.778-2.228 1.329-3.529 3.844-1.27 2.696-2.3 1.51z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m122.429 317.525-.84.011-.677.74-1.214-.867-1.75-.05-.368-3.655.668-1.606-45.06-.155 1.84-2.378 2.297-1.502 1.267-2.682 3.522-3.823 2.225-1.322 2.48-2.763 1.993-.783 2.09-4.58.25-2.745 1.656-3.253.652-4.04 3.1-.756 1.981-1.51-.384-2.11.614-1.072 2.353-.157 6.795-2.938.523 11.645 1.294.94 1.373 3.297 1.85 1.897 9.437.268-.126 6.725 1.86.162 1.097 3.612-.443 1.397-2.251 1.959zM36.154 320.374l2.45-.687-.296-1.82 1.78.248 2.95-2.129 1.41.751.332-.284-1.098-1.835.844-.312 1.193.602-.153-1.71-1.15-.855 1.504-.663.637-.934 1.27.349 1.212-.414-.49-1.171-1.226-.785.205-1.268 2.518 2.044.377-.756 3.131.311 2.556 1.829 1.2 1.984 3.413.793-.201 19.26 1.138.326.593 3.827-2.442 6.139.668 2.265 5.041 4.956 1.042 2.943 4.526 3.376 2.808 1.027 1.38 1.96.366 3.787-1.961 5.022-1.586 1.982 1.807 3.921.924 3.261-4.167 6.355-8.25 3.188-.398 3.216-13.75 3.501-14.114.026 15.161-3.073 1.529-2.108-9.94-9.602-.676-15.776-7.246-9.723-.045-4.123 3.19-2.302 1.126-.09.448-.824-.521-1.42-2.25-2.135.051-.941 4.136-4.314-2.435-2.106.452-5.5.809-.566.35-1.444-.974-.415-.881-1.755 1.74-1.822-.239-.983.676-1.222-.39-.66z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m91.287 333.118-29.723.042-1.141-.327.202-19.188 4.18.989 3.764-1.732.788-.96 4.059-.06 45.136.156-.668 1.61.37 3.664 1.751.05 1.217.868.678-.74.84-.012.033 16.04-5.292-.007zM2.535 277.318 2 262.472l2.404-27.034.815-6.513 33.584-.635.509.628-.36 1.635-.698-.376.21-1.455-2.147-.034-1.79.81-.312 1.016-1.082.457-.091 1.165.76 1.069-.077 7.137-2.141 2.189-.66 2.173.307 1.57-1.35 1.325-1.584-.168-2.018 1.644.106 2.874.935 1.54.44.26.464-1.483 1.303 1.655.542.736-.318 1.485-1.769-.027-1.902 1.5 1.612.604.505.77 1.962-.588.646 1.136-5.137 1.123-.636 3.151-1.23.927-1.62 2.772 1.63 4.341.69.518 2.247-.186 1.052.958.375 4.321zM35.873 320.2l-1.369-.564-1.51 1.387-21.22.14.116 2.318-7.326.203-1.103-24.988-.97-21.234 25.488.146.804 2.34-.747 1.153.921 4.304 4.315 1.701-1.5.27.316.803 4.481 2.47 2.29.4 1.883 2.828-.634 2.967-1.255 1.07 1.333.856-.865 1.619 1.344.203 1.102 3.684-1.32 2.735-.928-.996-.324 2.027-2.18-.072-.117.505 1.255 1.036-.924.928-.014.833-1.016-.016-1.106.996 3.22.741.513 1.967-.807 2.739.295 1.816z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m4.82 333.314-.365-9.517 7.35-.203-.117-2.318 21.29-.139 1.514-1.387 1.375.565 2.062.72.39.66-.675 1.218.239.98-1.74 1.819.88 1.75.975.415-.35 1.44-.81.565-.451 5.489 2.434 2.1-4.135 4.305-.051.938 2.25 2.13.521 1.417-.448.823-1.126.09-3.188 2.296.045 4.114-7.89 32.057-12.569-5.279-5.52.845-1.077-27.012z"/>
        </g>
        <g opacity=".7">
            <path fill="#D7D2CB" d="m157.494 255.81 12.83-.278.2-6.411 1.043-.109.118.849 1.224-.029-.041-1.873 3.536.645.086.7 1 .037.094 4.332 1.16.668-.09 1.352 6.189-.02-.285 7.208 8.91.278-.622 3.454.484 4.449-2.421 3.197-1.128 5.113-1.795 4.244-3.256.239-14.367-.578-9.646.46-2.617-.054 2.352-3.866.123-9.653-3.626.058-.301-1.95 1.515-2.071-.625-2.182.925-3.155z"/>
            <path fill="#D7D2CB" d="m140.113 255.996 3.089-.412.181-6.641 6.815.08-.046 6.792 7.652.04.958 5.035-.915 3.142.619 2.174-1.498 2.062.298 1.943 3.583-.057-.121 9.614-2.325 3.85 2.587.054.076 6.115-.878.079.12.768-2.874-.138.008 7.127-4.509-.084.077-1.196-1.312.064.086-5.74-1.382-.083-2.264 3.45-1.634-.09-3.085 3.06-.102-5.818-6.774.037-.024-10.77 3.582-.25-.134-12.866.59-.163-.05-1.861-.62-.02z"/>
            <path fill="#D7D2CB" d="m139.997 256.318-.229 9.32.628.019.05 1.866-.597.163.136 12.896-3.623.25.024 10.796-13.778.094-9.494-.27-1.861-1.91-1.382-3.319-1.3-.946-.527-11.729.186-6.788.015-9.873 3.597-.23zM184.312 317.311l-26.732.472-.435-19.968-.006-7.148 2.893.138-.118-.77.883-.08-.076-6.132 9.605-.46 14.306.576z"/>
            <path fill="#D7D2CB" d="m157.74 317.606.38 16.113-13.868.291-21.78-.518-.031-16.03.104-12.114 2.264-1.961.445-1.399-1.103-3.617-1.87-.165.127-6.736 13.77-.094 6.847-.037.103 5.803 3.119-3.052 1.65.09 2.29-3.441 1.395.083-.085 5.724 1.326-.064-.078 1.193 4.558.082zM188.068 283.78l1.778-4.216 1.117-5.08 2.399-3.177-.48-4.419.617-3.433 5.582-9.972 4.728.232.07-1.673 2.71.223-.008-.693.923-.108.227.702 1.876-.065-.055-9.777 1.065.046-.002-.803 10.402.233 1.963 2.167.594 1.486 2.019 1.696.31 1.254 4.08.002-.006-.655 4.731.682-.365 1.569.361 2.607-.908 2.929-3.062 5.847-1.386 4.19.273 5.664-2.145.472-2.928 2.377-.456 2.779-13.819-.313-.023 7.302z"/>
            <path fill="#D7D2CB" d="m210.182 306.872-.021-23.18.023-7.305 13.886.315.458-2.781 2.942-2.378 2.156-.473.094.396 1.016 3.767-.231 1.831.51 1.938 3.543 5.013 1.13 5.317.002 2.762-1.612 3.46.335 2.815-1.81 5.828.366 2.769zM184.14 317.202l.322-33.11 3.26-.237 22.418.074.021 23.115-.014 2.827-4.534.172-.077 2.67-3.521-.218-4.726-1.312-3.687.213-3.44 5.896z"/>
            <path fill="#D7D2CB" d="m238.636 333.498-32.933.02.256-16.356-15.436.167 3.415-5.961 3.66-.217 4.689 1.328 3.494.22.079-2.7 4.498-.174.014-2.86 22.748.095.091 2.339-1.508 2.53.666 3.547-.638 2.408.653 1.974 1.683.786 2.084 2.847 1.436 3.72z"/>
            <path fill="#D7D2CB" d="m157.63 317.835 26.689-.467 5.98.09 15.443-.166-.255 16.116h-.108l-37.168.602-10.202-.148z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m140.113 255.996 3.089-.412.181-6.641 6.815.08-.046 6.792 7.652.04.958 5.035-.915 3.142.619 2.174-1.498 2.062.298 1.943 3.583-.057-.121 9.614-2.325 3.85 2.587.054.076 6.115-.878.079.12.768-2.874-.138.008 7.127-4.509-.084.077-1.196-1.312.064.086-5.74-1.382-.083-2.264 3.45-1.634-.09-3.085 3.06-.102-5.818-6.774.037-.024-10.77 3.582-.25-.134-12.866.59-.163-.05-1.861-.62-.02z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m139.997 256.318-.229 9.32.628.019.05 1.866-.597.163.136 12.896-3.623.25.024 10.796-13.778.094-9.494-.27-1.861-1.91-1.382-3.319-1.3-.946-.527-11.729.186-6.788.015-9.873 3.597-.23zM184.312 317.311l-26.732.472-.435-19.968-.006-7.148 2.893.138-.118-.77.883-.08-.076-6.132 9.605-.46 14.306.576z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m157.74 317.606.38 16.113-13.868.291-21.78-.518-.031-16.03.104-12.114 2.264-1.961.445-1.399-1.103-3.617-1.87-.165.127-6.736 13.77-.094 6.847-.037.103 5.803 3.119-3.052 1.65.09 2.29-3.441 1.395.083-.085 5.724 1.326-.064-.078 1.193 4.558.082zM188.068 283.78l1.778-4.216 1.117-5.08 2.399-3.177-.48-4.419.617-3.433 5.582-9.972 4.728.232.07-1.673 2.71.223-.008-.693.923-.108.227.702 1.876-.065-.055-9.777 1.065.046-.002-.803 10.402.233 1.963 2.167.594 1.486 2.019 1.696.31 1.254 4.08.002-.006-.655 4.731.682-.365 1.569.361 2.607-.908 2.929-3.062 5.847-1.386 4.19.273 5.664-2.145.472-2.928 2.377-.456 2.779-13.819-.313-.023 7.302z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m210.182 306.872-.021-23.18.023-7.305 13.886.315.458-2.781 2.942-2.378 2.156-.473.094.396 1.016 3.767-.231 1.831.51 1.938 3.543 5.013 1.13 5.317.002 2.762-1.612 3.46.335 2.815-1.81 5.828.366 2.769zM184.14 317.202l.322-33.11 3.26-.237 22.418.074.021 23.115-.014 2.827-4.534.172-.077 2.67-3.521-.218-4.726-1.312-3.687.213-3.44 5.896z"/>
            <path stroke="#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m238.636 333.498-32.933.02.256-16.356-15.436.167 3.415-5.961 3.66-.217 4.689 1.328 3.494.22.079-2.7 4.498-.174.014-2.86 22.748.095.091 2.339-1.508 2.53.666 3.547-.638 2.408.653 1.974 1.683.786 2.084 2.847 1.436 3.72z"/>
        </g>
        <g opacity=".7">
          <path fill="#f16a64" d="m104.645 103.317 2.805-.97.511-.92-.482-1.544.783-1.212 1.734-.215.67-1.102 1.769-.1.82-1.1.965.439.367-1.036 2.947-.552 1.195-2.322 1.523-.63 1.455-3.435 1.212-.264.207-1.902 1.086.181-1.25-2.282.39-.438.959.702 2.107-1.247-.077-1.2.857.362.644-1.404 1.11.67.732-.54.665-2.001 2.928-3.245-.639-1.294 2.338-2.364-.116-1.128.705-.803 4.317-.464 9.893 13.252-2.027 4.274-.417 6.832-2.182 3.44 2.147.969-.551.766-.004 2.354-5.295 4.607-5.733.9-1.534 1.073-3.533.226-.006 2.611-4.909-.072-.125-.825-7.115-5.545-7.918-.035-3.855 2.36-1.235-.11-.09-2.241-.785-.096z"/>
          <path fill="#f16a64" d="m73.961 123.793.034-6.614-7.377-.38-.036-14.439-7.589-.188-.043-3.467.076-7.604 29.27.52 1.905-.515 1.204.29.548.76h3.064l.116 4.367 1.763 2.019-.434 2.151 6.228.233.119 1.233 1.937.982-.038 1.377.791.095.09 2.237-.106 1.151-1.281-.108-.07 1.226-1.583-.04-.143 1.113-.87.014-.143 2.601-.563-.126.006 1.191-.489-.05-.274 3.266-1.352-.147-.032 2.64-2.22-.13-.016 2.192-3.542.06-.227 1.52-2.218-.13.012 1.078-1.126-.175-.763 1.95 1.12 1.92-.542.582-2.338.279-1.169 1.68-.212-.561-.868.09-.21.923-3.423.07-.017-5.718-3.387-.002-.232-1.417zM134.942 128.874l-.09-.711-2.126-.102.082-5.069.49-.133.03-.969.601-.057.057-1.788.56.017.204-5.33-.747-.06-.157-.976-.743-.173-.006-2.203-2.47-.114.006-2.615 3.532-.226 1.533-1.074 5.727-.901 5.291-4.612.004-2.357.551-.768.955-.491-.056-1.686 3.27-3.408.708.21-.119 1.27.97.22 2.228-1.421 1.355-.104 1.885 1.975.868 3.1.93.255.664 1.41 1.758.174 1.328 2.7 1.917-.08.787 1.077.12 1.873-.929 1.798 1.539 3.046-2.514 2.15-1.67.613-.951 1.459-1.687.014-.27 3.388-1.045-.073-.921-1.49-1.833 1.02 2.001 1.636.567 1.998-.527 1.138-1.606 1.026.417 1.954-1.482.694-.037 1.08 1.415 1.205-.1.741-2.577 2.11-1.027 1.642-1.164.146-.442-1.245-.902.157-3.87 7.392-2.659 1.438-.09-8.604-7.13-.08.025-3.167z"/>
          <path fill="#f16a64" d="m98.467 152.91.105-2.62-1.19-.068-.092-.852-2.866-.147.021-.885-2.458-.135.032-1.257-1.08-.1.002-1.59-1.117-.101-.087-1-1.226-.215-.06-.667-1.19-.14.054-2.217-1.041-.173-.486-1.603-1.961-.749-.122-1.15-1.977-.12-.018-.889-1.267-.103-.127-.965-.747-.054 1.017-1.94 2.75.692.301-1.695 1.675.334-.863-1.428.209-.921.862-.09.21.56 1.163-1.677 2.327-.278.538-.58-1.113-1.917.758-1.947 1.12.175-.011-1.075 2.206.128.225-1.515 3.522-.06.018-2.19 2.207.132.032-2.635 1.344.147.273-3.26.487.05-.007-1.19.56.127.143-2.597.863-.014.142-1.11 1.575.04.07-1.224 1.275.108.105-1.149 1.237.107 3.856-2.35 7.924.037 7.119 5.52.125.821 4.913.074 2.473.113.006 2.191.744.171.157.972.748.059-.205 5.302-.56-.018-.057 1.78-.602.055-.03.963-.49.134-.083 5.04 2.13.103.09.707-.02 1.778-1.565-.16-.057.591-.999-.326-.027.851-.891-.213.122.893-.9.047.165.746-.86-.027-.12 1.479-1.161.076-.025.814-4.473-.392.065 1.556-1.647.064-.103.995-1.162.116-.034 1.145-2.17.086-.172 2.105-1.363.627.12.966-4.031 1.33.022 1.885-3.66.085-.41 1.32-.969-.027-.442 1.171-1.129.377.065 1.702-1.118-.03.09.815-1.791.064.125.853-.705-.055-.132.736-2.39.122z"/>
          <path fill="#f16a64" d="m101.298 153.078 2.389-.122.132-.734.707.055-.126-.851 1.792-.063-.09-.814 1.118.03-.064-1.7 1.13-.374.442-1.17.97.027.41-1.317 3.659-.085-.02-1.882 4.033-1.327-.121-.963 1.362-.626.175-2.1 2.17-.085.033-1.144 1.163-.115.104-.995 1.647-.063-.065-1.553 4.476.391.024-.812 1.162-.076.12-1.474.86.026-.164-.745.899-.048-.122-.89.892.212.027-.85.999.328.056-.59 1.567.158.02-1.774 2.43.039-.025 3.143 7.144.08.09 8.539 1.975 2.39.151 1.076-1.262.881-.357 1.76.841 1.614-2.264 1.883-2.952.127.683 1.939-.437.797-1.68.058-.523 1.2.12 2.031-1.112-.182-1.309 1.322.915 2.866 1.473 1.668.014.7-1.232.109-.49 1.384-1.37.914-.843 5.458-3.008-.165-2.67-2.658-.818.013-.005-1.069-18.128.255-.081 1.58-1.191.004-.098-.481-1.003-.063-.056-.664-1.189-.068-.046-1.032-.779-.095-.034-1.474-1.335-.183.08-4.38-.927-.135-.093-.703-2.492-.137z"/>
          <path fill="#f16a64" d="m101.215 197.066.051-10.317 8.955-.312-.173-17.783.08-1.575 18.218-.254.005 1.064.822-.011 2.684 2.648 3.023.167-.397 1.859.71 2.368-.18 3.294 1.569 2.285.637 2.217 2.012 1.236.346.78-.577 2.839 1.761 1.996 1.335.188.262 1.107 2.507 1.91-2.806.348.18 3.591-20.318-.105.001 3.656-13.722-.088-7.029-.075z"/>
          <path stroke= "#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m104.645 103.317 2.805-.97.511-.92-.482-1.544.783-1.212 1.734-.215.67-1.102 1.769-.1.82-1.1.965.439.367-1.036 2.947-.552 1.195-2.322 1.523-.63 1.455-3.435 1.212-.264.207-1.902 1.086.181-1.25-2.282.39-.438.959.702 2.107-1.247-.077-1.2.857.362.644-1.404 1.11.67.732-.54.665-2.001 2.928-3.245-.639-1.294 2.338-2.364-.116-1.128.705-.803 4.317-.464 9.893 13.252-2.027 4.274-.417 6.832-2.182 3.44 2.147.969-.551.766-.004 2.354-5.295 4.607-5.733.9-1.534 1.073-3.533.226-.006 2.611-4.909-.072-.125-.825-7.115-5.545-7.918-.035-3.855 2.36-1.235-.11-.09-2.241-.785-.096z"/>
          <path stroke= "#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m73.961 123.793.034-6.614-7.377-.38-.036-14.439-7.589-.188-.043-3.467.076-7.604 29.27.52 1.905-.515 1.204.29.548.76h3.064l.116 4.367 1.763 2.019-.434 2.151 6.228.233.119 1.233 1.937.982-.038 1.377.791.095.09 2.237-.106 1.151-1.281-.108-.07 1.226-1.583-.04-.143 1.113-.87.014-.143 2.601-.563-.126.006 1.191-.489-.05-.274 3.266-1.352-.147-.032 2.64-2.22-.13-.016 2.192-3.542.06-.227 1.52-2.218-.13.012 1.078-1.126-.175-.763 1.95 1.12 1.92-.542.582-2.338.279-1.169 1.68-.212-.561-.868.09-.21.923-3.423.07-.017-5.718-3.387-.002-.232-1.417zM134.942 128.874l-.09-.711-2.126-.102.082-5.069.49-.133.03-.969.601-.057.057-1.788.56.017.204-5.33-.747-.06-.157-.976-.743-.173-.006-2.203-2.47-.114.006-2.615 3.532-.226 1.533-1.074 5.727-.901 5.291-4.612.004-2.357.551-.768.955-.491-.056-1.686 3.27-3.408.708.21-.119 1.27.97.22 2.228-1.421 1.355-.104 1.885 1.975.868 3.1.93.255.664 1.41 1.758.174 1.328 2.7 1.917-.08.787 1.077.12 1.873-.929 1.798 1.539 3.046-2.514 2.15-1.67.613-.951 1.459-1.687.014-.27 3.388-1.045-.073-.921-1.49-1.833 1.02 2.001 1.636.567 1.998-.527 1.138-1.606 1.026.417 1.954-1.482.694-.037 1.08 1.415 1.205-.1.741-2.577 2.11-1.027 1.642-1.164.146-.442-1.245-.902.157-3.87 7.392-2.659 1.438-.09-8.604-7.13-.08.025-3.167z"/>
          <path stroke= "#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m98.467 152.91.105-2.62-1.19-.068-.092-.852-2.866-.147.021-.885-2.458-.135.032-1.257-1.08-.1.002-1.59-1.117-.101-.087-1-1.226-.215-.06-.667-1.19-.14.054-2.217-1.041-.173-.486-1.603-1.961-.749-.122-1.15-1.977-.12-.018-.889-1.267-.103-.127-.965-.747-.054 1.017-1.94 2.75.692.301-1.695 1.675.334-.863-1.428.209-.921.862-.09.21.56 1.163-1.677 2.327-.278.538-.58-1.113-1.917.758-1.947 1.12.175-.011-1.075 2.206.128.225-1.515 3.522-.06.018-2.19 2.207.132.032-2.635 1.344.147.273-3.26.487.05-.007-1.19.56.127.143-2.597.863-.014.142-1.11 1.575.04.07-1.224 1.275.108.105-1.149 1.237.107 3.856-2.35 7.924.037 7.119 5.52.125.821 4.913.074 2.473.113.006 2.191.744.171.157.972.748.059-.205 5.302-.56-.018-.057 1.78-.602.055-.03.963-.49.134-.083 5.04 2.13.103.09.707-.02 1.778-1.565-.16-.057.591-.999-.326-.027.851-.891-.213.122.893-.9.047.165.746-.86-.027-.12 1.479-1.161.076-.025.814-4.473-.392.065 1.556-1.647.064-.103.995-1.162.116-.034 1.145-2.17.086-.172 2.105-1.363.627.12.966-4.031 1.33.022 1.885-3.66.085-.41 1.32-.969-.027-.442 1.171-1.129.377.065 1.702-1.118-.03.09.815-1.791.064.125.853-.705-.055-.132.736-2.39.122z"/>
          <path stroke= "#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m101.298 153.078 2.389-.122.132-.734.707.055-.126-.851 1.792-.063-.09-.814 1.118.03-.064-1.7 1.13-.374.442-1.17.97.027.41-1.317 3.659-.085-.02-1.882 4.033-1.327-.121-.963 1.362-.626.175-2.1 2.17-.085.033-1.144 1.163-.115.104-.995 1.647-.063-.065-1.553 4.476.391.024-.812 1.162-.076.12-1.474.86.026-.164-.745.899-.048-.122-.89.892.212.027-.85.999.328.056-.59 1.567.158.02-1.774 2.43.039-.025 3.143 7.144.08.09 8.539 1.975 2.39.151 1.076-1.262.881-.357 1.76.841 1.614-2.264 1.883-2.952.127.683 1.939-.437.797-1.68.058-.523 1.2.12 2.031-1.112-.182-1.309 1.322.915 2.866 1.473 1.668.014.7-1.232.109-.49 1.384-1.37.914-.843 5.458-3.008-.165-2.67-2.658-.818.013-.005-1.069-18.128.255-.081 1.58-1.191.004-.098-.481-1.003-.063-.056-.664-1.189-.068-.046-1.032-.779-.095-.034-1.474-1.335-.183.08-4.38-.927-.135-.093-.703-2.492-.137z"/>
          <path stroke= "#E5554F" strokeWidth="3.25" strokeLinecap="round" strokeLinejoin="round" d="m101.215 197.066.051-10.317 8.955-.312-.173-17.783.08-1.575 18.218-.254.005 1.064.822-.011 2.684 2.648 3.023.167-.397 1.859.71 2.368-.18 3.294 1.569 2.285.637 2.217 2.012 1.236.346.78-.577 2.839 1.761 1.996 1.335.188.262 1.107 2.507 1.91-2.806.348.18 3.591-20.318-.105.001 3.656-13.722-.088-7.029-.075z"/>
        </g>
        <g transform="translate(28 .393)">
            <ellipse fill="#E5554F"  opacity=".943" cx="80.873" cy="40.097" rx="15.93" ry="15.85"/>
            <text fontFamily="WorkSans-Medium, Work Sans" fontSize= "25" fontWeight="400" letterSpacing="-.714" fill="#E7CEB5">
                            <tspan x="75"  y="48">1</tspan>
                        </text>
            <ellipse fill="#E5554F"  opacity=".943" cx="158.07" cy="115.281" rx="15.93" ry="15.85"/>
            <text fontFamily="WorkSans-Medium, Work Sans" fontSize="25" fontWeight="400" letterSpacing="-.714" fill="#E7CEB5">
                            <tspan x="150"  y="123">2</tspan>
                        </text>
            <ellipse fill= "#E5554F"  opacity=".943" cx="15.93" cy="159.579" rx="15.93" ry="15.85"/>
            <text fontFamily="WorkSans-Medium, Work Sans" fontSize="25" fontWeight="400" letterSpacing="-.714" fill= "#E7CEB5">
                            <tspan x="7.75" y="168.2">3</tspan>
                        </text>
            <ellipse fill="#E5554F" opacity=".943" cx="151.535" cy="204.69" rx="15.93" ry="15.85"/>
            <text fontFamily="WorkSans-Medium, Work Sans" fontSize= "25" fontWeight="400" letterSpacing="-.714" fill="#E7CEB5">
                            <tspan x= "144" y="212.4">5</tspan>
                        </text>
            <ellipse fill="#E5554F"  opacity=".943" cx="153.986" cy="288.409" rx="15.93" ry="15.85"/>
            <text fontFamily="WorkSans-Medium, Work Sans" fontSize="25" fontWeight="400" letterSpacing="-.714" fill= "#E7CEB5">
                            <tspan x="145.5" y="297.7">6</tspan>
                        </text>
            <ellipse fill="#E5554F"  opacity=".943" cx="21.239" cy="289.222" rx="15.93" ry="15.85"/>
            <text fontFamily="WorkSans-Medium, Work Sans" fontSize="25"  fontWeight="400" letterSpacing="-.714" fill="#E7CEB5" >
                            <tspan x="13.2" y="300">7</tspan>
                        </text>
            <ellipse fill="#E5554F" opacity=".943" cx="91.085" cy="126.254" rx="15.93" ry="15.85"/>
            <text fontFamily="WorkSans-Medium, Work Sans" fontSize="25" fontWeight="400" letterSpacing="-1" fill="#E7CEB5">
                            <tspan x="82" y="135">4</tspan>
                        </text>
        </g>
    </g>
</svg>

)