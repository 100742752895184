import { Close } from "../../Icons/Close";
import Modal from "../../Modal";
import { Map } from "../../Icons/Map"
import tw from "twin.macro";
import { numberWithCommas, toUsd } from "../../../helpers";
import { CompareRegionsModalProps } from "../Results.d";
import AnalyticsEventsClient from "../../../clients/AnalyticsEvents"



const CompareRegionsModal: React.FC<CompareRegionsModalProps> = ({ open, setOpen, regions, highestValues, activeJobLevel }) => {

  const closeModal = () => {
    setOpen(!open)
    document.body.style.overflow = "scroll"

    AnalyticsEventsClient.event({
      eventType: "click",
      eventName: "click_close_all_regions",
      eventMessage: "User exited all regions",
      visitorID: localStorage.getItem("visitorID")
    })
  }


return (
<Modal
blue={false}
open={open}
modalCss={[tw`w-screen lg:max-w-7xl`]}
>
<div css={[tw`flex justify-end mb-10 mt-4 lg:mt-20 mr-2 lg:mb-24`]}>
  <Close css={[tw`text-white`]} onClick={ closeModal}/> 
</div>
<div css={[tw`grid grid-cols-1  lg:grid-cols-3 lg:gap-12`]}>
  <div css={[tw`col-span-1 w-full mx-auto `]}>
    <p css={[tw`text-beige pb-4 text-lg`]}>
      See how these AL regions compare
    </p>
    <div css={[tw`mx-auto flex justify-center pb-5`]}><Map  /></div>
  </div>
  <div css={[tw`lg:col-span-2 flex justify-between px-2 pb-5`]}>
    <table css={[tw`table-auto w-full`]}>
      <thead>
        <tr>
          <th css={[tw`text-left pl-2`]}>Region</th>
          <th css={[tw`text-sm`]}>Current Openings</th>
          <th css={[tw`text-sm`]}>Total Jobs</th>
          <th css={[tw`text-sm`]}>Avg Annual Wage</th>
        </tr>
      </thead>
      <tbody>
        {regions.map((region: any, index: number, key: number) => {
          return (
            <tr
              css={
                index % 2 !== 0 ? [tw`bg-gray-100/20`] : null
              }
              key={index}
            >
              <td
                css={[
                  tw`text-left flex items-center content-center justify-start justify-items-start h-full py-2`,
                ]}
              >
                <div
                  css={[
                    tw`h-6 w-6 rounded-full bg-red-300 flex items-center justify-center text-beige m-2`,
                  ]}
                >
                  {region.regionId}
                </div>
                {region.majorCity}
              </td>
              <td
                css={
                  highestValues.open ==
                  region?.jobs[activeJobLevel]?.totalOpen
                    ? [tw`font-bold text-beige`]
                    : null
                }
              >
                {numberWithCommas(
                  region?.jobs[activeJobLevel]?.totalOpen
                )}
              </td>
              <td
                css={
                  highestValues.jobs ==
                  region?.jobs[activeJobLevel]?.totalJobs
                    ? [tw`font-bold text-beige`]
                    : null
                }
              >
                {numberWithCommas(
                  region?.jobs[activeJobLevel]?.totalJobs
                )}
              </td>
              <td
                css={
                  highestValues.salary ==
                  region?.jobs[activeJobLevel]?.avgSalary
                    ? [tw`font-bold text-beige`]
                    : null
                }
              >
                {toUsd(region?.jobs[activeJobLevel]?.avgSalary)}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </div>
</div>
</Modal> 
)}

export default CompareRegionsModal


